/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* roboto-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoLight';
  src: url('/extras/fonts/roboto-v30-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoBold';
  src: url('/extras/fonts/roboto-v30-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #a4a4a2;
  color: #a4a4a2;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #575756 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'RobotoBold';
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #004485;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #004485;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1200px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'RobotoBold';
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 68, 133, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #575756;
  font-family: 'RobotoLight', sans-serif;
  line-height: 1.28571429;
}
#home {
  float: left;
  max-width: 270px;
}
.logo {
  width: 100%;
  height: auto;
}
.cb-layout1 #head {
  position: absolute;
  display: table;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  width: 80%;
  padding: 0 10%;
  z-index: 1;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(3, 3, 3, 0.15) 49%, rgba(0, 0, 0, 0.15) 50%, rgba(243, 243, 243, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(3, 3, 3, 0.15) 49%, rgba(0, 0, 0, 0.15) 50%, rgba(243, 243, 243, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(3, 3, 3, 0.15) 49%, rgba(0, 0, 0, 0.15) 50%, rgba(243, 243, 243, 0) 100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(3, 3, 3, 0.15) 49%, rgba(0, 0, 0, 0.15) 50%, rgba(243, 243, 243, 0) 100%);
}
.cb-layout1 h1 {
  display: table-cell;
  vertical-align: middle;
}
.cb-layout2 #head {
  width: 100%;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: block;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.main-nav {
  float: left;
}
.mood {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hr {
  height: 1px;
  color: #ebebeb;
  float: left;
  width: 100%;
  background-color: #ebebeb;
}
.area:empty + hr {
  display: none;
}
.popover-toggle {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
  background: #e53012 url(/images/popover-toggle.svg) no-repeat 50%;
  background-size: 27%;
  left: 50%;
}
.popover-toggle:active {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.35);
}
.c2 .popover-toggle {
  display: none;
}
.popover {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  display: none;
  will-change: opacity;
  transition: opacity 0.4s, -webkit-backdrop-filter 0.3s, backdrop-filter 0.3s;
  background: rgba(255, 255, 255, 0.01);
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  visibility: hidden;
}
.popover-close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff url(/images/popover-close.png) no-repeat 50%;
  background-size: 40%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);
  -webkit-tap-highlight-color: transparent;
}
.popover-close:active {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
}
.popover.cb-toggle-target-active {
  display: block;
  visibility: visible;
  position: fixed;
  z-index: 1;
  opacity: 1;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
.popover-inner {
  padding: 5% 10% 10%;
  position: relative;
  background: #fff;
  margin: 20%;
  *zoom: 1;
  transition: box-shadow 0.2s 0.1s ease-out;
}
.popover-inner:before,
.popover-inner:after {
  display: table;
  content: '';
}
.popover-inner:after {
  clear: both;
}
.popover.cb-toggle-target-active .popover-inner {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 60px 60px rgba(0, 0, 0, 0.4);
}
#contactlinks .meta {
  font-weight: normal;
  font-family: 'RobotoBold';
  color: #fff !important;
  font-size: 21px;
  font-size: 2.1rem;
  margin-top: 40px;
  display: block;
  padding: 0.6em;
  text-align: center;
  line-height: 1.1;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.service_call {
  background: #6e368c;
}
.service_call:hover,
.service_call:focus {
  background: #8b44b1;
}
.service_call:active {
  background: #602f7a;
}
.service_consulting {
  background: #009fe3;
}
.service_consulting:hover,
.service_consulting:focus {
  background: #17baff;
}
.service_consulting:active {
  background: #008dca;
}
.service_quote {
  background: #033c7b;
}
.service_quote:hover,
.service_quote:focus {
  background: #0454ad;
}
.service_quote:active {
  background: #023062;
}
#expo,
#slides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#slides,
#expo .slide {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
  overflow: hidden;
}
#expo .cb-landscape {
  height: 100% !important;
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
}
#expo div.link {
  background: none;
}
a.cb-slide-previous,
a.cb-slide-next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  background: url(/images/cb-slide-previous.svg) no-repeat;
  opacity: 0.9;
  border-radius: 50%;
}
a.cb-slide-previous:hover,
a.cb-slide-next:hover,
a.cb-slide-previous:focus,
a.cb-slide-next:focus {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
}
a.cb-slide-previous:active,
a.cb-slide-next:active {
  background-color: rgba(0, 0, 0, 0.16);
}
a.cb-slide-previous {
  left: 10px;
}
a.cb-slide-next {
  right: 10px;
  background-image: url(/images/cb-slide-next.svg);
}
.cb-index-some {
  display: none;
}
.cb-index-all {
  display: block;
}
table.link {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  background: none;
  width: 100%;
  display: block;
  text-align: center;
  z-index: 1;
}
table.link > tbody {
  display: inline;
}
.cb-index-all > td {
  display: inline-block;
}
.cb-index-all a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: inline-block;
  margin: 0 4px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #fff;
  -webkit-transition: background 1s;
  transition: background 1s;
}
.cb-index-all a:hover,
.cb-index-all a:focus {
  background: #024485;
}
.link:hover .cb-index-all a,
.cb-index-all a:hover,
.cb-index-all a:focus {
  -webkit-transition: none;
  transition: none;
}
.cb-index-all .this > a {
  background: #024485;
}
.search {
  float: left;
  width: 180px;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 2;
}
.search__input {
  float: left;
  width: 100%;
  border: 2px solid #004485;
  border-radius: 20px;
  color: #000;
  padding: 0 30px 0 15px;
  min-height: 36px;
  font-size: 14px;
  line-height: 1.28571429;
}
.search__input::-webkit-input-placeholder {
  color: #000 !important;
}
.search__submit {
  position: absolute;
  right: 2px;
  top: 0;
  width: 30px;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-blue.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  cursor: pointer;
}
#root div.south {
  width: 100%;
  margin: 0;
}
#root div.south div.body,
#root div.south div.part {
  margin: 0;
}
#root div.south div.part {
  width: 100%;
}
#root div.south div.unit {
  float: none;
  display: inline-block;
  position: relative;
}
#root div.south div.head h2 {
  margin: 0 20px !important;
  font-size: 24px;
  font-weight: normal;
  display: block;
  padding: 20px 0 10px;
}
#edit div.south div.unit {
  float: left;
  display: block;
}
#view div.south div.head {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 1;
  width: 100%;
}
#view div.south div.head h2 {
  color: #fff;
  padding: 0;
}
#view div.south div.head h2 a {
  color: #fff;
  display: block;
}
#view div.south div.body {
  position: relative;
}
#view div.south .pict > a:after {
  content: '';
  position: absolute;
  height: 0;
  padding-top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
#carousel-width {
  float: left;
  width: 100%;
}
#carousel {
  margin: 0 auto;
  position: relative;
  margin-bottom: 60px;
  margin-top: 10px;
  *zoom: 1;
  height: auto;
  overflow: hidden;
  opacity: 1;
  -o-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
#carousel:before,
#carousel:after {
  display: table;
  content: '';
}
#carousel:after {
  clear: both;
}
#view #carousel {
  overflow: hidden;
}
#carousel-center {
  float: left;
  width: 100%;
}
#carousel--inner {
  float: left;
  position: relative;
}
.carousel-animation {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}
#carousel-links {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 4;
}
#carousel-links .arrow {
  display: block;
  width: 50px;
  height: 50px;
  margin-top: -25px;
}
#carousel-links .arrow:hover,
#carousel-links .arrow:focus {
  cursor: pointer;
}
#carousel-arrow-left {
  float: left;
  margin-left: 20px;
  background: url(/images/carousel-left.svg) no-repeat 0 0;
}
#carousel-arrow-right {
  float: right;
  margin-right: 20px;
  background: url(/images/carousel-right.svg) no-repeat 0 0;
}
.carousel-shadow {
  z-index: 1;
  position: absolute;
  bottom: 0;
  height: 100%;
  opacity: 0.6;
}
#edit .carousel-shadow {
  display: none;
}
.carousel-shadow.left {
  left: 0;
  background-image: -moz-linear-gradient(0% 1% 0deg, #000000 0%, transparent 100%);
  background-image: -webkit-gradient(linear, 0% 1%, 100% 1%, color-stop(0, #000000), color-stop(1, transparent));
  background-image: -webkit-linear-gradient(0deg, #000000 0%, transparent 100%);
  background-image: -o-linear-gradient(0deg, #000000 0%, transparent 100%);
  background-image: linear-gradient(90deg, #000000 0%, transparent 100%);
}
.carousel-shadow.right {
  right: 0;
  background-image: -moz-linear-gradient(100% 0% -180deg, #000000 0%, transparent 100%);
  background-image: -webkit-gradient(linear, 100% 0%, 0% 0%, color-stop(0, #000000), color-stop(1, transparent));
  background-image: -webkit-linear-gradient(-180deg, #000000 0%, transparent 100%);
  background-image: -o-linear-gradient(-180deg, #000000 0%, transparent 100%);
  background-image: linear-gradient(270deg, #000000 0%, transparent 100%);
}
.footer {
  float: left;
  width: 100%;
  background: #575756;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer a {
  color: #fff;
}
.footer a:hover,
.footer a:focus {
  color: #dddddd;
}
#social {
  position: absolute;
  left: 0;
  bottom: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
}
#social .meta {
  float: left;
  width: 60px;
  height: 60px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  margin-right: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-facebook-gray.svg);
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_einstein {
  width: 98px;
  height: 80px;
  background-image: url(/images/social-einstein-gray.svg);
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.8;
}
@media (max-width: 759px) {
  #social {
    bottom: 60px;
  }
}
.krengerglas {
  *zoom: 1;
}
.krengerglas:before,
.krengerglas:after {
  display: table;
  content: '';
}
.krengerglas:after {
  clear: both;
}
@media (max-width: 1199px) {
  .krengerglas {
    margin-bottom: 160px;
  }
}
@media (max-width: 759px) {
  .krengerglas {
    margin-bottom: 180px;
  }
}
.fn,
.adr,
.street-address,
.tel,
.vcardemail,
.url {
  display: block;
}
.fn {
  font-weight: normal;
  font-family: 'RobotoBold';
  padding-bottom: 0.21em;
}
a.tel {
  margin-top: 1.4em;
}
.value {
  white-space: nowrap;
}
.footer-nav {
  margin-bottom: 140px;
}
@media (max-width: 1199px) {
  .footer-nav {
    margin-bottom: 40px;
  }
}
#cmsbox {
  position: absolute;
  right: 0;
  bottom: 40px;
  z-index: 2;
  font-size: 12px;
  font-size: 1.2rem;
  color: #686867;
  float: right;
  text-transform: lowercase;
}
@media (max-width: 759px) {
  #cmsbox {
    bottom: 20px;
  }
}
#cmsbox > .meta {
  color: #797978;
}
#cmsbox > .meta:hover,
#cmsbox > .meta:focus {
  color: #9a9a9a;
}
.navi,
.navi > .item {
  float: left;
}
.item > .navi,
.item > .navi .item {
  width: 100%;
}
.menu {
  color: #575756;
  display: block;
  line-height: 1.1;
}
.main-nav .item > .navi {
  display: none;
}
.cb-layout1 h1 {
  color: #fff;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.35);
}
.cb-layout2 h1,
.unit h2 {
  line-height: 1.2;
}
strong {
  font-weight: normal;
  font-family: 'RobotoBold';
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: none;
}
.gist:before {
  content: '•';
  position: absolute;
  color: #004485;
  left: 0;
}
a {
  color: #024485;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #02519e;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.cb-layout2 div.base {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout2 h1,
.unit h2 {
  font-weight: normal;
  font-family: 'RobotoLight', sans-serif;
}
.cb-layout2 h1,
.unit h2,
.unit h3 {
  text-transform: uppercase;
}
.pure h2,
.pure .part,
.pure table,
.pure .foot {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.pure .part,
.pure .table {
  width: 100% !important;
}
.pure .body,
.pure .show:first-child {
  margin-top: 0;
}
.pure .head + .body {
  margin-top: 0.4em;
}
.pure .head + .body .show:first-child {
  margin-top: 0.4em;
}
.seam {
  background: #ebebeb;
  padding: 10px 0;
}
/* unit edge.wide is layout helper */
.edge.wide.note {
  width: 100% !important;
  margin: 0 !important;
}
#edit .edge.wide.note {
  position: relative;
  border-top: 2px dashed #ddd;
  color: #ccc;
}
#edit .edge.wide.note:after {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0 0.5em 1px;
  border-radius: 3px;
  background: #eee;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3), 0 0 0 1px #ddd inset, 0 0 0 2px #fff inset;
  color: #444;
  content: 'Layout-Helper';
  text-shadow: 0 1px 0 #fff;
  letter-spacing: 0.03em;
  font-size: 11px;
  font-family: 'Helvetica Neue', sans-serif;
}
#edit .edge.wide.note h2 {
  color: #ccc;
}
div.edge.wide.note,
div.edge.wide.note div.body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  min-height: 0;
}
.cb-layout2 .base h2 {
  text-transform: none;
}
#work + .navigation {
  margin-top: 50px;
}
#edit .area {
  border-top: 2px dashed #ccc;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2016-01-05 *******/
/* Changelog

2016-01-05
added Calendar (partial copy of manage.css)

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #575756;
  background-color: #fff;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.11666667em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #a4a4a2;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #00376c #001d39 #001d39 #00376c;
  border-radius: 2px;
  background-color: #004485;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 16, 31, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #00376c #001d39 #001d39 #00376c;
  background-color: #004c94;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #00376c #001d39 #001d39 #00376c;
  background-color: #002a52;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  line-height: 1.5;
  position: absolute;
  z-index: 100;
  text-align: right;
  border: 3px solid #666;
  background: #999;
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  font-weight: 700;
  text-align: center;
  color: #eee;
}
.calendar tbody {
  color: #999;
  background-color: #eee;
}
td.show {
  font-weight: 700;
  color: #fff;
  background: #7be;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.calendar th > a:hover,
.calendar td > a:hover,
.calendar th > a:focus,
.calendar td > a:focus {
  color: #fff;
  background: #ccc;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'RobotoLight', sans-serif;
  line-height: 1.42857143;
  color: #575756;
}
@media (max-width: 759px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 759px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 759px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #004485;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #004485;
  font-size: 16px;
  font-weight: normal;
  font-family: 'RobotoBold';
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #00376c #001d39 #001d39 #00376c;
  border-radius: 2px;
  background-color: #004485;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 16, 31, 0.75);
  line-height: 2.2em;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  border-color: #00376c #001d39 #001d39 #00376c;
  background-color: #004c94;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
#disp .foot input:active,
#disp .foot a:active {
  outline: none;
  border-color: #00376c #001d39 #001d39 #00376c;
  background-color: #002a52;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 759px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 759px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 759px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 759px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 759px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 759px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 759px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'RobotoLight', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 759px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 759px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/
.togglenavigation {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
}
.togglenavigation__outer {
  width: 28px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.togglenavigation__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.togglenavigation__inner,
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  width: 28px;
  height: 4px;
  background-color: #878787;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.14s;
  transition-timing-function: ease;
}
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  content: "";
  display: block;
}
.togglenavigation__inner::before {
  top: -8px;
}
.togglenavigation__inner::after {
  bottom: -8px;
}
/*
 * Squeeze
 */
.togglenavigation--squeeze .togglenavigation__inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.togglenavigation--squeeze .togglenavigation__inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}
.togglenavigation--squeeze .togglenavigation__inner::after {
  transition: bottom 0.1s 0.14s ease, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/******* layout-small.less 2013-1-16 *******/
#home {
  width: 56.89655172%;
  margin-top: 19px;
}
.desk {
  width: 90.625%;
  max-width: 720px;
}
html.cb-toggle-target-active {
  height: 100%;
  overflow: hidden;
}
.cb-toggle-target-active .navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.95);
  z-index: 4;
  padding-bottom: 60px;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
.togglenavigation {
  right: 0;
  top: 58px;
  position: absolute;
  z-index: 1;
}
.main-nav {
  margin-top: 20px;
}
.mood {
  margin-top: 7px;
  height: 280px;
}
@media (min-width: 400px) {
  .mood {
    height: 320px;
  }
}
@media (min-width: 450px) {
  .mood {
    height: 360px;
  }
}
@media (min-width: 540px) {
  .mood {
    height: 400px;
  }
}
.cb-layout2 .mood {
  height: 0;
  padding-bottom: 64.13793103%;
}
.cb-slide-previous,
.cb-slide-next {
  transform: scale(0.6);
}
a.cb-slide-previous {
  left: 12px;
  transform-origin: 0 50%;
}
a.cb-slide-next {
  right: 12px;
  transform-origin: 100% 50%;
}
.cb-layout1 h1 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.125;
  position: relative;
  top: -30px;
  vertical-align: middle;
}
.cb-index-all a {
  width: 9px;
  height: 9px;
}
.cb-layout2 #head {
  margin-top: 20px;
}
@media only screen and (max-width: 759px) {
  div.south div.unit {
    width: 499px;
  }
  #carousel {
    width: 499px;
  }
  #carousel--inner {
    width: 519px;
  }
}
@media only screen and (max-width: 550px) {
  div.south div.unit {
    width: 375px;
  }
  #carousel {
    width: 375px;
  }
  #carousel--inner {
    width: 395px;
  }
}
@media only screen and (max-width: 400px) {
  div.south div.unit {
    width: 290px;
  }
  #carousel {
    width: 290px;
  }
  #carousel--inner {
    width: 310px;
  }
}
@media only screen and (max-width: 759px) {
  div.south div.unit {
    margin: 0 10px;
  }
  #carousel--inner {
    margin-left: -10px;
  }
  #carousel-links .arrow {
    width: 22px;
    height: 22px;
    margin-top: -11px;
    background-size: 100% auto;
  }
  #carousel-arrow-right {
    margin-right: 10px;
  }
  #carousel-arrow-left {
    margin-left: 10px;
  }
  #root div.south div.head h2 {
    margin: 0 10px !important;
    font-size: 16px;
  }
  #view div.south div.head {
    bottom: 12px;
  }
  .carousel-shadow {
    height: 30%;
    width: 100%;
    bottom: 0;
  }
  .carousel-shadow.left {
    display: none;
  }
  .carousel-shadow.right {
    background-image: -moz-linear-gradient(0% 100% 90deg, #000000 0%, transparent 100%);
    background-image: -webkit-gradient(linear, 0% 100%, 0% 1%, color-stop(0, #000000), color-stop(1, transparent));
    background-image: -webkit-linear-gradient(90deg, #000000 0%, transparent 100%);
    background-image: -o-linear-gradient(90deg, #000000 0%, transparent 100%);
    background-image: linear-gradient(0deg, #000000 0%, transparent 100%);
  }
}
.hr {
  margin-top: 7px;
  margin-bottom: 7px;
}
.popover-toggle {
  width: 30px;
  height: 30px;
  top: 60%;
  margin-left: -15px;
}
.footer {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.38461538;
}
.cb-goto-top {
  right: 0;
  top: 20px;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  visibility: hidden;
  background: url(/images/cb-goto-top.svg) no-repeat 50%;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.218s;
}
.cb-goto-top:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.cb-goto-top-visible {
  opacity: 1;
  visibility: visible;
}
.footer-nav {
  display: none;
}
.krengerglas {
  margin-top: 19px;
  line-height: 1.38461538;
}
@media (max-width: 1199px) {
  .navi,
  .navi > .item {
    width: 100%;
  }
}
.navi > .item {
  position: relative;
}
.main-nav .menu {
  font-size: 20px;
  font-size: 2rem;
  padding: 0.95em 6.55172414%;
}
.item > .cb-toggle {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
  height: 58px;
  width: 48px;
  background: url(/images/cb-toggle-down.png) no-repeat 96%;
}
.item.cb-toggle-target-active > .cb-toggle {
  background-image: url(/images/cb-toggle-up.png);
}
.item-empty > .cb-toggle,
.item > .navi .cb-toggle {
  display: none;
}
@media (max-width: 1199px) {
  .main-nav > .navi > .path > .path {
    color: #024485;
    font-weight: normal;
    font-family: 'RobotoBold';
    padding-bottom: 0.7em;
  }
  .main-nav > .navi > .path,
  .main-nav > .navi > .cb-toggle-target-active {
    padding-bottom: 10px;
  }
}
@media (max-width: 1199px) {
  .main-nav {
    height: 0;
    overflow: hidden;
  }
  .cb-toggle-target-active .main-nav {
    height: auto;
    overflow: visible;
  }
  .main-nav > .navi {
    height: 0;
    opacity: 0;
    transform: translateY(-40px);
    transition: transform 0.3s ease-out, opacity 0.218s 0.1s ease-out;
  }
  .cb-toggle-target-active .main-nav > .navi,
  .cbe--edit-navigation .main-nav > .navi {
    opacity: 1;
    height: auto;
    transform: none;
  }
}
@media (max-width: 1199px) {
  .main-nav .item.cb-toggle-target-active > .navi {
    display: block;
  }
}
.main-nav .item > .navi .menu {
  padding: 0.1em 13.10344828%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 4.13793103%;
  margin-left: 4.13793103%;
}
.area .part,
.area > .grid table {
  margin-right: 4.13793103%;
  margin-left: 4.13793103%;
  width: 91.72413793%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 91.72413793%;
}
.main {
  margin-top: 15px;
}
.cb-layout2 .main {
  margin-top: 5px;
}
.unit {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
}
.cb-layout2 h1,
.unit h2,
.unit h3 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
h1,
h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */